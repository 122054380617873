import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { media } from 'utils'
import { useOpenModalFromEvent } from 'hooks'

import { Container, Row, Col } from 'components/Grid'
import { Section } from 'components/Common'
import Components from 'components/components'

import EventCard from '../EventCard'
import HubSpotFormModal from '../EventFormModal'

import { EventsGrid } from './styles'
import SbEditable from 'storyblok-react'

import WithEvents from '../WithEvents'

function EventsSectionGrayGrid({ blok, allEvents: events, lang }) {
  const { modalOpen, toggleModalVisibility } = useOpenModalFromEvent()

  const eventsWithModals = events.filter(event => event.content.signup_form)
  const maxAmount = blok.max_amount * 1
  const dEvents = maxAmount ? events.slice(0, maxAmount) : events

  /* Methods */
  const renderEvent = props => (
    <EventContent
      {...props}
      locale={lang}
      no_location={Boolean(blok.no_location)}
      no_label={Boolean(blok.no_label)}
      key={props.uuid}
      toggleModalVisibility={toggleModalVisibility}
      no_date={Boolean(blok.no_date)}
    />
  )

  return (
    <SbEditable content={blok}>
      <Section
        paddingTop={blok.padding_top || 106}
        paddingBottom={blok.padding_bottom || 64}
        paddingTopMb={blok.padding_top_mobile || 120}
        paddingBottomMb={blok.padding_bottom_mobile || 80}
        css="background-color: transparent;"
      >
        {!isEmpty(blok.intro) &&
          blok.intro.map(
            blok =>
              Components[blok.component] &&
              React.createElement(Components[blok.component], {
                key: blok._uid,
                blok,
              }),
          )}
        <EventsGrid>
          <Container>
            <Row>
              {dEvents.map((el) => {
                return (
                  <Col
                    key={el.uuid}
                    width={[1, 1 / 2, 1 / 2, 1 / 4]}
                    px={[2, 3, 2, 2, 2]}
                    py={2}
                  >
                    {renderEvent(el)}
                  </Col>
                )
              })}
            </Row>
          </Container>
        </EventsGrid>
        {!isEmpty(blok.footer) &&
          blok.footer.map(
            blok =>
              Components[blok.component] &&
              React.createElement(Components[blok.component], {
                key: blok._uid,
                blok,
              }),
          )}
      </Section>

      {eventsWithModals.length > 0 &&
        eventsWithModals.map(
          event =>
            event.uuid === modalOpen && (
              <HubSpotFormModal
                handleCloseModal={toggleModalVisibility}
                content={event.content}
                key={event.id}
              />
            ),
        )}
    </SbEditable>
  )
}

export default WithEvents(EventsSectionGrayGrid)

const EventContent = styled(EventCard)`
  padding: 20px;
  background-color: #fff;
  z-index: 10;
  margin: auto;
  margin-top: 0;
  border: 1px solid #000;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
