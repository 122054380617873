import React from 'react'
import Page from 'components/page'
import isEmpty from 'lodash/isEmpty'

import { removeGlobalSlug } from 'utils/helpers'

function EventPage(props) {
  const { blok, ...rest } = props
  
  const schemaLD = [
    ...(rest.schemaLD ? [...rest.schemaLD] : []),
    {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: blok.name,
      startDate: blok.date,
      endDate: blok.endDate || blok.date,
      location: {
        '@type': 'Place',
        name: blok.location,
        address: blok.location,
      },
      offers: {
        '@type': 'Offer',
        url: blok.signup_link?.cached_url
          ? `$siteURL/${removeGlobalSlug(blok.signup_link?.cached_url)}`
          : blok.signup_link?.url,
        price: 0,
        priceCurrency: 'USD',
        availability: 'https://schema.org/InStock',
        validFrom: blok.date,
      },
      description: blok.description,
      image: [
        'https://a.storyblok.com/f/59501/359x127/8bde45723e/ave-logo.png',
      ],
      performer: {
        type: 'Organization',
        name: 'Avenues',
      },
    },
  ]
  return (
    !isEmpty(blok.page) && (
      <Page blok={blok.page[0]} {...rest} schemaLD={schemaLD} />
    )
  )
}
export default EventPage
