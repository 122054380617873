import React, { useRef } from 'react'

import Components from 'components/components'
import { StyledContent } from 'components/Modal/Modal/styles'
import { StyledModal, CloseButton } from 'components/Modal/styles'
import XIcon from 'static/img/x_icon_dark.svg'

const HubSpotFormModal = ({ handleCloseModal, content }) => {
  const modalRef = useRef(null)
  const signUpForm = [...content.signup_form].pop()
  return (
    <StyledModal ref={modalRef}>
      <StyledContent preset="splash_form">
      <div  style={{padding:'30px'}}>
      {signUpForm.body.map(
                  blok =>
                    Components[blok.component] &&
                    React.createElement(Components[blok.component], {
                      key: blok._uid,
                      blok,
                    }),
                )}
        </div>            
      
        <CloseButton onClick={handleCloseModal}>
          <img src={XIcon} width={22} />
        </CloseButton>
      </StyledContent>
    </StyledModal>
  )
}

export default HubSpotFormModal
