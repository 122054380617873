import styled, { css } from 'styled-components'
import media from 'utils/media'

export const VideoDiv = styled.div`
  position: relative;
  padding-bottom: 57.666667%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  ${media.greaterThan('lg')`
    padding-bottom: 47.466667%;
  `}

  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 946px;
    transform: translateX(-50%);
  }
`
export const Video = styled.video`
  max-width: 100%;
`

export const ViewDetailsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.blueDark};
  font-size: 16px;
  width: 100%;
  padding: 12px 0;
  transition: margin 0.3s;
  color: ${({ theme, fontColor }) =>
    fontColor ? theme.colors[fontColor] : theme.colors.blueDark};
  border-top: 1px solid;
  border-bottom: ${({ isExpanded }) => (isExpanded ? '1px solid' : 'none')};
  border-color: ${({ theme }) => theme.colors.neutral};

  &:focus {
    outline: none;
  }

  ${media.greaterThan('sm')`
    max-width: 459px;
  `}
  ${({ isMargin }) =>
    isMargin
      ? css`
          margin-top: 7px;
        `
      : css`
          margin-top: 15px;
          ${media.greaterThan('sm')`
          margin-top: 55px;
        `}
        `};
  ${({ isEvents }) =>
    isEvents
      ? css`
          cursor: pointer;
        `
      : css`
          img {
            opacity: 0.2;
          }
          color: ${({ theme }) => theme.colors.neutral} !important;
        `};
`
export const EventsGrid = styled.div`

  position: relative;

  ${media.greaterThan('lg')`
    margin-top: 20px;
  `}

  >div >div{
    padding:0 12px;
  }
  
  &:after {
    content: ' ';
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - 200px);
    background-color: ${({ theme }) => theme.colors.neutral};
  }
`
