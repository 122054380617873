import React, { useContext } from 'react'

import ContentContext from 'lib/content.context'
import { filterEvents, filterByDate } from './eventsHelper'
import { useDeviceSize } from 'hooks'
import renderElement from 'utils/renderElement'

export default function WithEvents(Component) {
  return props => {
    var { allEvents } = useContext(ContentContext)
    var { isMobile } = useDeviceSize()

    const isInPreview = checkIfInPreview()
    const disableDateFiltering =
      isInPreview && props.blok.preview_disable_date_filtering

    let parsedEvents = []
    if (isInPreview) {
      parsedEvents = filterEvents(
        allEvents
          .filter(filterNonEvent)
          .filter(event => !event.content.hide_event),
        props.blok,
      )
    } else {
      parsedEvents = filterEvents(
        allEvents.filter(event => !event.content.hide_event),
        props.blok,
      )
    }

    parsedEvents.sort((a, b) => {
      if (a.content.pinned && !b.content.pinned) {
        return -1
      } else if (!a.content.pinned && b.content.pinned) {
        return 1
      } else {
        return 0
      }
    })

    var filteredEvents = parsedEvents.filter(ev =>
      filterByDate(ev.content.date),
    )

    filteredEvents.sort((a, b) => {
      if (a.content.pinned && !b.content.pinned) {
        return -1
      } else if (!a.content.pinned && b.content.pinned) {
        return 1
      } else {
        return 0
      }
    })

    if (
      (filteredEvents.length === 0 && !isInPreview) ||
      (isInPreview && props.blok.preview_show_alternative)
    ) {
      return renderAlternativeContent(props.blok.alternative_content)
    } else {
      return (
        <Component
          {...props}
          allEvents={disableDateFiltering ? parsedEvents : filteredEvents}
          {...{ isMobile }}
        />
      )
    }
  }
}

function filterNonEvent(ev) {
  return ev.content.component === 'event'
}

function renderAlternativeContent(sections) {
  if (!sections || !sections.length) return null
  return sections.map(el =>
    renderElement(el.component, { blok: el, key: el._uid }),
  )
}

function checkIfInPreview() {
  if (typeof window === 'undefined') return false
  if (window.location && window.location.pathname.match(/^\/?editor\/?$/)) {
    return true
  }
  return false
}
