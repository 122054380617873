import React from 'react'
import styled from 'styled-components'
import { enUS, pt, zhCN } from 'date-fns/locale'
import _isEmpty from 'lodash/isEmpty'
import { formatTime } from './utils'

import { Link } from 'gatsby'
import { useState, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'

import { removeGlobalSlug, withSlash } from 'utils/helpers'

const isChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

const locales = {
  en: enUS,
  pt,
  zh: zhCN,
}

function EventCard(props) {
  const [registerUrl, updateRegisterUrl] = useState('default')

  /** we don't need lang prop, but don't want it in rest neither */
  const {
    content,
    heading = 'h5',
    noHeading,
    locale: lang = 'en',
    lang: _,
    no_location = false,
    no_date = false,
    no_label = false,
    toggleModalVisibility,
    ...rest
  } = props
  const locale = locales[lang.replace('default', 'en')]

  useEffect(() => {
    const extHref = content.signup_link.url
    const cachedUrl = props.full_slug
    const intHref = cachedUrl && withSlash(removeGlobalSlug(cachedUrl))
    const url = !_isEmpty(extHref) ? extHref : intHref

    updateRegisterUrl(url)
  }, [])

  /* Methods */
  function triggerChinaEventAnalytics() {
    // baidu
    if (typeof window._hmt === 'function') {
      window._hmt.push(['_trackEvent', 'Button', 'PIE', 'Click'])
    }

    // symphony
    if (typeof window.XmoTag15443 === 'function') {
      window.XmoTag15443()
    }
  }

  const handleToggleModalVisibility = ev => {
    ev.preventDefault()
    toggleModalVisibility(props.uuid)
  }

  const date = content.date && new Date(content.date.replace(/-/g, '/'))
  const buttonLabel =
    content.type == 'ondemand'
      ? 'watch'
      : content.waitlisted === true
      ? 'Join the Waitlist'
      : 'register'
  const signUpForm = content.signup_form

  const renderModal = () => {
    return (
      <RegisterLink
        to={'#'}
        css="margin: 20px 0 0 0; position: relative; padding: 5px 20px;"
        onClick={handleToggleModalVisibility}
      >
        <FormattedMessage id={buttonLabel} />
      </RegisterLink>
    )
  }

  const renderButton = () => {
    if (content.button_text && content.button_text !== '') {
      return (
        <RegisterLink
          to={registerUrl}
          css="margin: 20px 0 0 0; position: relative; padding: 5px 20px;"
        >
          {content.button_text}
        </RegisterLink>
      )
    } else {
      return (
        <RegisterLink
          to={registerUrl}
          css="margin: 20px 0 0 0; position: relative; padding: 5px 20px;"
        >
          <FormattedMessage id={buttonLabel} />
        </RegisterLink>
      )
    }
  }

  return (
    <>
      <EventContent {...rest}>
        <Header>
          {no_label && (
            <Label>
              <FormattedMessage id={content.type} />
            </Label>
          )}
          {!noHeading && <Title as={heading}>{content.name + (content.subheadline ? " " + content.subheadline : "")}</Title>}
        </Header>
        <Details>
          {!_isEmpty(content.date_alternative) ? (
            <>
              <Flex>
                <EventContentP>{content.date_alternative}</EventContentP>
              </Flex>
              <Flex>
                <EventContentP>{content.location}</EventContentP>
              </Flex>
            </>
          ) : (
            <>
              <Flex>
                <EventContentP>
                  {date && formatTime(date, 'PPP', locale)}
                </EventContentP>
              </Flex>
              <Flex>
                <EventContentP>
                  {date && formatTime(date, 'p', locale)}
                  {content.date_end &&
                    ' - ' +
                      formatTime(
                        new Date(content.date_end.replace(/-/g, '/')),
                        'p',
                        locale,
                      )}{' '}
                  {content.location === "Online" ? "EST" : ""}
                </EventContentP>
              </Flex>
              <Flex>
                {content.location && (
                  <EventContentP>{content.location}</EventContentP>
                )}
              </Flex>
            </>
          )}
          {signUpForm && signUpForm.length > 0 ? renderModal() : renderButton()}
        </Details>
      </EventContent>
    </>
  )
}

export default React.memo(EventCard)

export const EventContent = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  outline: none;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  border-radius: 20px;
`
export const Header = styled.div`
  margin-bottom: 20px;
`

export const Title = styled.h5`
  position: relative;
  font-size: 20px !important;
  font-weight: 800;
  margin-top: 0;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';
`
export const Label = styled.p`
  font-size: 20px !important;
  font-weight: 400;
  color: #238e69;
  display: block;
`
export const EventP = styled.p`
  font-size: 16px !important;
  min-width: 90px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
`
export const EventContentP = styled.p`
  font-size: 14px !important;
  font-weight: 800;
`
export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`
export const Details = styled.div`
  align-self: stretch;
`
export const HiddenEventButton = styled.button`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
export const RegisterLink = styled(props => <Link {...props} />)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: normal;
  cursor: pointer;
  border: none;
  padding: 0 32px;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.25s;
  font-family: Halyard-SemiBold;
  font-feature-settings: 'ss18';

  color: #fff !important;
  background-color: ${({ theme }) => theme.colors.green};

  &:hover {
    background-color: #165941;
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`
