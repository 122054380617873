import _curry from 'lodash/curry'
import isEmpty from 'lodash/isEmpty'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import parseISO from 'date-fns/parseISO'

export function filterEvents(data, blok) {
  var newData = data
  var filterEvent = _curry(filterEventResult)

  if (!isEmpty(blok.events_location)) {
    var filterByLocation = filterEvent(blok.events_location)('location')
    newData = newData.filter(filterByLocation)
  }
  if (!isEmpty(blok.events_type)) {
    var filterByType = filterEvent(blok.events_type)('type')
    newData = newData.filter(filterByType)
  }
  if (!isEmpty(blok.events_grade)) {
    var filterByGrade = filterEvent(blok.events_grade)('grade_level')
    newData = newData.filter(filterByGrade)
  }

  return newData
}

function filterEventResult(filterValue, filterKey, data) {
  if (Array.isArray(filterValue)) {
    return filterValue.includes(data.content[filterKey])
  } else {
    return data.content[filterKey] === filterValue
  }
}

export function filterByDate(date) {
  var currentDate = new Date(new Date().setHours(0, 0, 0, 0))
  var eventDate = parseISO(date)

  return isAfter(eventDate, currentDate) || isEqual(eventDate, currentDate)
}
